import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { ICreateAccount } from "../../auth";


const createAccountSchemas = [
  Yup.object({
    email: Yup.string().required().email().label('Email Address'),
    password: Yup.string().required().min(8).label("Password"),
    full_name: Yup.string().required().label('Full Name'),
    phone: Yup.string().required().label("Phone Number"),
    referrer: Yup.string().label("Referrer")
  }),
  Yup.object({
    instagram_handle: Yup.string().required().label('Instagram Handle'),
    institution: Yup.string().label('Institution'),
    discord_username: Yup.string().required().label('Discord Username'),
  }),
  Yup.object({}),
]

const inits: ICreateAccount = {
  email: "",
  password: "",
  full_name: "",
  phone: "",
  referrer: "",
  instagram_handle: "",
  occupation: "",
  discord_username: "",
}

interface RegisterResp {
  status: boolean,
  referralCode?: string
}

const submitSignup = async (userData: ICreateAccount): Promise<RegisterResp> => {
  const formData = new FormData();
  formData.append("email", userData.email);
  formData.append("password", userData.password);
  formData.append("full_name", userData.full_name);
  formData.append("phone", userData.phone);
  formData.append("instagram_handle", userData.instagram_handle);
  formData.append("occupation", userData.occupation);
  formData.append("discord_username", userData.discord_username);
  if (userData.institution) {
    formData.append("institution", userData.institution);
  }
  if (userData.referrer) {
    formData.append("referrer", userData.referrer);
  }

  const requestOptions: RequestInit = {
    method: 'POST',
    body: formData,
    redirect: 'follow'
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/`, requestOptions);
    const data = await res.json();
    if (res.ok) return {
      status: true,
      referralCode: data.referral_code
    };
    else {
      const msg = await res.json();
      Swal.fire(
          'Error!',
          `Please check your input! </br> detail: ${msg}`,
          'error'
      )
      return { status: false };
    }
  } catch (e) {
    Swal.fire(
        'Error!',
        `We have encountered an unhandled error! </br> detail: ${e}`,
        'error'
    )
    return { status: false };
  }
}

export {createAccountSchemas, inits, submitSignup}
