/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {Horizontal} from "../../wizards/components/Horizontal";

export function Registration() {

  return (
    <Horizontal />
  )
}
