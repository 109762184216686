import React, {FC} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Account Info</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Please fill the form below.
        </div>
      </div>


      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Email Address</label>

        <Field
            type='email'
            className='form-control form-control-lg form-control-solid'
            name='email'

        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='email' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Password</label>

        <Field
            type='password'
            className='form-control form-control-lg form-control-solid'
            name='password'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='password' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Full Name</label>

        <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='full_name'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='fullName' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3 required'>Phone Number</label>

        <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='phone'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='phone' />
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <label className='form-label mb-3'>Referral</label>

        <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='referral'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='referral' />
        </div>
      </div>

    </div>
  )
}

export {Step2}
