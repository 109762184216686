import React, { FC, useEffect } from "react";
import {KTIcon} from '../../../../../_metronic/helpers'

const Step5: FC<{referralCode?: string}> = ({referralCode}) => {

  return (
    <div className='w-100'>
      <div className='pb-8 pb-lg-10'>
        <h2 className='fw-bolder text-dark'>Your Are Done!</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Congratulations on registering to Artivo
        </div>
      </div>

      <div className='mb-0'>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Join Our Discord!</h4>
              <div className='fs-6 text-gray-600'>
                Join our community on discord, click
                <a href='https://community.artivo.id' className='fw-bolder'>
                  {' '}
                  here
                </a>
                .
              </div>
            </div>
          </div>
        </div>

        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mt-6'>
          <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>Refer Your Friends!</h4>
              <div className='fs-6 text-gray-600'>
                Refer your friends here. Top referrers will get cool prizes. Your code is: {referralCode}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step5}
