import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Member Info</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Please fill in your social media details.
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Instagram Handle</label>

        <Field name='instagram_handle' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='instagram_handle' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Discord Username</span>
        </label>

        <Field
          name='discord_username'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='discord_username' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Occupation</label>

        <Field
          as='select'
          name='occupation'
          className='form-select form-select-lg form-select-solid'
        >
          <option></option>
          <option value='student'>Student</option>
          <option value='full time'>Full Time Worker</option>
          <option value='part time'>Part Time Worker</option>
          <option value='freelance'>Freelancer</option>
          <option value='other'>Others</option>
        </Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='occupation' />
        </div>
      </div>

      <div className='fv-row mb-0'>
        <label className='fs-6 fw-bold form-label'>Institution</label>

        <Field name='institution' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='institution' />
        </div>
      </div>
    </div>
  )
}

export {Step3}
