/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { date } from "yup";
import { baseEdit, ContestModel, useAuth, UserModel } from "../../../modules/auth";
import { getContests, submitContest, updateUser } from "../../../modules/auth/core/_requests";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

type Props = {
  className: string
}

const ContestTable: React.FC<Props> = ({ className }) => {
  const [activeContest, setActiveContest] = useState<ContestModel>();
  const [contestList, setContestList] = useState<ContestModel[]>([]);
  const [loading, setLoading] = useState(false);
  const submissionScheme = Yup.object().shape({
    submission_link: Yup.string().required().label("Submission Link").url()
  });

  const modelCloseBtnRef = createRef<HTMLButtonElement>();

  const { auth } = useAuth();

  const onSubmit = async (values: { submission_link: string }) => {
    if (!activeContest) return;
    setLoading(true);
    try {
      await submitContest(auth?.access!, activeContest.id, values.submission_link);
      Swal.fire("Success!", "Your submission data has been successfully created.", "success");
    } catch (e) {
      Swal.fire("Error!", "Your request cannot be fulfilled.", "error");
    }
    await updateContest();
    setLoading(false);
  };

  const updateContest = async () => {
    const contestReq = await getContests(auth?.access!);
    const contests = contestReq.data;
    setContestList(contests);
    console.log(contests);
  };

  useEffect(() => {
    updateContest();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Contest List</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
            <tr className="fw-bold text-muted">
              <th className="min-w-150px">Contest Name</th>
              <th className="min-w-140px">Description</th>
              <th className="min-w-120px">Duration</th>
              <th className="min-w-120px">Status</th>
              <th className="min-w-100px text-end">Actions</th>
            </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {contestList.map(contest => (
              <tr>
                <td>
                  <span className="text-dark fw-bold text-hover-primary d-block fs-6">{contest.name}</span>
                </td>
                <td>
                  <span className="text-muted fw-bold text-hover-primary d-block fs-6">{contest.description}</span>
                </td>
                <td>
                  <span className="text-muted fw-bold text-hover-primary d-block fs-6">
                    {contest.start + "-" + contest.end}
                  </span>
                </td>
                <td>
                  {contest.joined ? <span className="badge badge-light-success fs-8 fw-bold">Joined</span> :
                    contest.open ? <span className="badge badge-light-primary fs-8 fw-bold">Open</span> :
                      <span className="badge badge-light-danger fs-8 fw-bold">Closed</span>
                  }
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <button
                      className="btn btn-primary btn-bg-light btn-sm me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#submissionModal"
                      onClick={() => setActiveContest(contest)}
                      disabled={contest.joined || !contest.open}
                    >
                      Apply
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      <div className="modal fade" tabIndex={-1} id="submissionModal">
        <Formik validationSchema={submissionScheme} initialValues={{ submission_link: "" }} onSubmit={onSubmit}>
          {() => (
            <Form noValidate className="form">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Submit your Project</h5>
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                      />
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="card-body border-top p-9">
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">
                          Submission Link
                        </label>

                        <div className="col-lg-8 fv-row">
                          <Field
                            type="url"
                            className="form-control form-control-lg form-control-solid"
                            name="submission_link"
                          />
                          <div className="text-danger mt-2">
                            <ErrorMessage name="submission_link" />
                          </div>
                        </div>
                      </div>

                      <button ref={modelCloseBtnRef} style={{display: "none"}}></button>

                    </div>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" data-bs-dismiss="modal" className="btn btn-primary" disabled={loading}>
                      {!loading && "Save Changes"}
                      {loading && (
                        <span className="indicator-progress" style={{ display: "block" }}>
                        Please wait...{" "}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

    </div>
  );
};

export { ContestTable };
