import React, {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'
import {Avatar} from "@files-ui/react";
import './Step4.css';

const imageSrc =
    "https://placehold.co/600x200?text=Upload+Image";

const Step4: FC<{setIsUploaded: React.Dispatch<React.SetStateAction<boolean>>}> = ({setIsUploaded}) => {
  const [imageSource, setImageSource] = React.useState(imageSrc);
  const handleChangeSource = (selectedFile: any) => {
    setIsUploaded(true)
    setImageSource(selectedFile);
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Twibbon Uplaod</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Please post the twibbon on your social media account and tag at least 3 of your friends. You can get the twibbon info
          <a href='https://docs.google.com/document/d/e/2PACX-1vQjQ996gYe9cbnMX817QrPNJ-tk5_UDCwcCb0ZikfOpnuNsb3rXdBQjde9NgWH69HnB66IGy18Gt21F/pub' target="_blank" className='text-primary fw-bolder'>
            {' '}
            here
          </a>
          .
        </div>
      </div>

      <Avatar src={imageSource} alt="Avatar" style={{ width: "100%" }} onChange={handleChangeSource}
              emptyLabel={"Click to upload image..."} changeLabel={"Click to upload image..."}/>

    </div>
  )
}

export {Step4}
