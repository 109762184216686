/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from "react";
import { KTIcon } from "../../../../../_metronic/helpers";
import { Modal } from "react-bootstrap";
import { useAuth } from "../../core/Auth";
import Swal from "sweetalert2";

const DiscordModal: FC = () => {
  const { currentUser, updateUser } = useAuth();
  const [loading, setLoading] = useState(false);


  const checkUser = async () => {
    setLoading(true);
    const user = (await updateUser())!;
    if (user.joined_discord) {
      Swal.fire("Thank you!", "Thank you for joining our discord! you may now access our site.", "success");
    } else {
      Swal.fire("Not found!", "We have not detected your account on our discord. Please check again the instructions", "error");
    }
    setLoading(false)
  };

  return (
    <Modal className="modal" show={!currentUser?.joined_discord}>
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content rounded">
          <div className="modal-header justify-content-end border-0 pb-0">
          </div>

          <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
            <div className="mb-13 text-center">
              <h1 className="mb-3">Join our discord</h1>

              <div className="text-muted fw-bold fs-5">
                Before you can enter this website, you are required to <a href="https://community.artivo.id" target="_blank"
                   className="link-primary fw-bolder">
                  join our community
                </a> on discord. If you already joined please message the Artivo's bot with this message <code>&gt;login {currentUser?.email} {currentUser?.referral_code}</code>
              </div>
            </div>

            <div className="d-flex flex-center flex-row-fluid pt-12">

              <button type="button" onClick={checkUser} className="btn btn-primary" disabled={loading}>
                {!loading && "Check Now"}
                {loading && (
                  <span className="indicator-progress" style={{ display: "block" }}>
                        Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { DiscordModal };
