/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { ContestSubmissionModel, useAuth } from "../../../modules/auth";
import { getSubmissions } from "../../../modules/auth/core/_requests";
type Props = {
  className: string
}

const SubmissionsTable: React.FC<Props> = ({ className }) => {
  const [activeSubmission, setActiveSubmission] = useState<ContestSubmissionModel>();
  const [contestList, setContestList] = useState<ContestSubmissionModel[]>([]);


  const { auth } = useAuth();


  const updateContest = async () => {
    const submissionsReq = await getSubmissions(auth?.access!);
    const submissions = submissionsReq.data;
    setContestList(submissions);
  };

  useEffect(() => {
    updateContest();
  }, []);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Submissions List</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
            <tr className="fw-bold text-muted">
              <th className="min-w-150px">Contest Name</th>
              <th className="min-w-140px">Submission Link</th>
              <th className="min-w-120px">Status</th>
              <th className="min-w-100px text-end">Details</th>
            </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {contestList.map(submission => (
              <tr>
                <td>
                  <span className="text-dark fw-bold text-hover-primary d-block fs-6">{submission.contest}</span>
                </td>
                <td>
                  <a className="text-muted fw-bold text-hover-primary d-block fs-6" href={submission.submission_link}>Here</a>
                </td>
                <td>
                  {!submission.graded ? <span className="badge badge-light-primary fs-8 fw-bold">Waiting</span> :
                    submission.winner ? <span className="badge badge-light-success fs-8 fw-bold">Winner</span> :
                      <span className="badge badge-light fs-8 fw-bold">Graded</span>
                  }
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <button
                      className="btn btn-primary btn-bg-light btn-sm me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#submissionDetailModal"
                      onClick={() => setActiveSubmission(submission)}
                    >
                      Details
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      <div className="modal fade" tabIndex={-1} id="submissionDetailModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Submission Detail</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="card-body border-top p-9">
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Contest Name</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{activeSubmission?.contest}</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Submission Link</label>

                  <div className='col-lg-8'>
                    <a className="text-muted fw-bold text-hover-primary d-block fs-6" href={activeSubmission?.submission_link}>Here</a>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Status</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{!activeSubmission?.graded ? "Waiting" :
                      activeSubmission?.winner ? "Winner" : "Graded"
                    }</span>
                  </div>
                </div>

                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-muted'>Feedback</label>

                  <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{activeSubmission?.feedback}</span>
                  </div>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export { SubmissionsTable };
