import React, {FC, useEffect, useRef, useState} from 'react'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {
  createAccountSchemas,
  inits,
  submitSignup,
} from './CreateAccountWizardHelper'
import { Link } from "react-router-dom";
// import Swal from "sweetalert2";
import { ICreateAccount } from "../../auth";
import Swal from "sweetalert2";

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)
  const [isImageUpload, setImageUploaded] = useState(false)
  const [referralCode, setReferralCode] = useState('')

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1) {
      // submit here
      const {status, referralCode} = await submitSignup(values)
      setReferralCode(referralCode || '')
      if (status) {
        Swal.fire(
          'Success!',
          `Your account has been successfully created.`,
          'success'
        )
        stepper.current.goNext();
      } else return
    } else if (stepper.current.currentStepIndex === stepper.current.totalStepsNumber) {
    } else {
      stepper.current.goNext();
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber - 1)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div className='card'>
      <div className='card-body'>
        <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
        >
          <div className='stepper-nav mb-5'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Account Info</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Member Info</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Twibbon Upload</h3>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <h3 className='stepper-title'>Completed</h3>
            </div>
          </div>

          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step2 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3 />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step4 setIsUploaded={setImageUploaded} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step5 referralCode={referralCode} />
                </div>

                <div className='d-flex flex-stack pt-15'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                      Back
                    </button>
                  </div>

                  <div>
                    {(stepper?.current?.currentStepIndex || 0) === (stepper?.current?.totalStepsNumber || 9999) ? (
                      <Link to='/auth/login'>
                        <button
                          className='btn btn-lg btn-primary me-3'
                        >
                          <span className='indicator-label'>
                            Sign In
                            <KTIcon iconName='entrance-left' className='fs-3 ms-2 me-0' />
                          </span>
                        </button>
                      </Link>
                    ) : (
                      <button
                        type='submit'
                        className='btn btn-lg btn-primary me-3'
                        disabled={stepper?.current?.currentStepIndex === 3 && !isImageUpload}
                      >
                        <span className='indicator-label'>
                          {isSubmitButton ? "Submit" : "Continue"}
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { Horizontal };
